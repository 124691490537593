<template>
  <BaseBonusPanel :type="bonus.type">
    <template #title>
      {{ content.title }}
    </template>
    <template #subtitle>
      <p
        class="subtitle"
        :class="{ green: bonus.status === 'readyToClaim' }"
        data-t="subtitle"
      >
        <img
          v-if="isHiddenAmount"
          :src="spoilerImg"
          alt="spoiler"
          width="28"
          height="14"
        />
        <span v-else>{{ format(bonus.amount || '0') }}</span>
        <StIcon :name="currencyIcon" :size="14" />
      </p>
    </template>
    <template #actions>
      <StButton
        size="m"
        class="button"
        :type="bonus.status === 'readyToClaim' ? 'primary' : 'ghost'"
        :disabled="bonus.status === 'waitingForAccrual'"
        :loading="status === 'pending'"
        @click="onBonusSubmit"
      >
        <span v-if="bonus.status === 'waitingForAccrual'" class="countdown">
          {{ countdownToAccrual }}
        </span>
        <span v-else-if="bonus.status === 'readyToClaim'">
          {{ t('bonuses.take') }}
        </span>
        <span v-else>
          {{ t('bonuses.play') }}
        </span>

        <ClientOnly>
          <StLottie
            v-if="status === 'success'"
            class="animation"
            :animation-data="claimAnimation"
          />
        </ClientOnly>
      </StButton>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useRegularBonus } from '../../RegularBonuses/useRegularBonus'
import spoilerImg from '../../../assets/spoiler.svg'
import type { RegularBonus } from '../../../stores/useRegularBonusesStore'

interface Props {
  bonus: RegularBonus
}

const props = defineProps<Props>()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'claim'): void
}>()

const { bonus } = toRefs(props)

const {
  content,
  handleBonusButton,
  status,
  countdownToAccrual,
  currencyIcon,
  format,
  error,
  claimAnimation,
  isHiddenAmount,
} = useRegularBonus(bonus)

const toast = useToast()

async function onBonusSubmit() {
  await handleBonusButton()
  /* для проигрыша анимации */
  await delay(500)
  emit('claim')
}

watch(
  () => error.value,
  (newError) => {
    if (!newError) return

    toast.open({
      label: t('bonuses.error'),
      type: 'negative',
    })
  },
)
</script>

<style scoped>
.title {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.subtitle {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  &.green {
    color: var(--text-success);
  }
}

.countdown {
  min-width: 60px;
}

.button {
  position: relative;
}

.animation {
  position: absolute;
  overflow: visible;
}
</style>
