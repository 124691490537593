<template>
  <article class="bonus-panel">
    <img
      v-if="imageByType"
      :src="imageByType"
      alt="bonus"
      :width="44"
      :height="44"
      class="bonus-image"
      :class="{ paused }"
    />
    <StIcon
      v-if="paused"
      class="pause-icon"
      size="16"
      name="pause-bold-solid"
    />
    <div class="wrapper">
      <header class="header">
        <h4 class="title" data-t="title">
          <slot name="title"></slot>
        </h4>
        <slot name="wager"></slot>
      </header>
      <div
        v-if="isProgressVisible"
        class="rolling-progress"
        data-t="rolling-progress"
      >
        <div v-if="!paused" class="progress-bar"></div>
        <slot name="minmax"></slot>
      </div>
      <slot name="subtitle"></slot>
    </div>
    <slot name="actions"></slot>
  </article>
</template>

<script setup lang="ts">
import freebetImage from '../assets/freebet.png'
import freespinImage from '../assets/freespin.png'
import reloadImage from '../assets/reload.png'
import rakebackImage from '../assets/rakeback.png'
import depositImage from '../assets/deposit.png'
import cashbackImage from '../assets/cashback.png'

interface Props {
  type:
    | 'deposit'
    | 'freespin'
    | 'freebet'
    | 'cashback'
    | 'rakeBack'
    | 'reload'
    | string
  progress?: string
  paused?: boolean | null
}

const props = defineProps<Props>()

const imageByType = computed(
  () =>
    ({
      deposit: depositImage,
      freespin: freespinImage,
      freebet: freebetImage,
      reload: reloadImage,
      cashback: cashbackImage,
      rakeBack: rakebackImage,
    })[props.type],
)

const isProgressVisible = computed(
  () => props.progress && props.progress !== '100',
)
const progressPercents = computed(() => `${props.progress}%`)
</script>

<style scoped>
.bonus-panel {
  position: relative;

  overflow: hidden;
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  min-width: 328px;
  padding: var(--spacing-075) var(--spacing-150) var(--spacing-075)
    var(--spacing-100);

  background: var(--background-primary);
  border-radius: var(--border-radius-100);

  &:hover {
    background: var(--background-secondary);
  }
}

.bonus-image {
  min-width: 44px;
}

.paused {
  filter: blur(1px);
}

.pause-icon {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-050);
}

.title {
  overflow: hidden;

  max-width: 190px;
  margin: 0;

  font: var(--mobile-text-semibold);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rolling-progress {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}

.progress-bar {
  position: relative;

  height: 6px;

  background: url('../assets/progress-bg.svg') var(--border-primary);
  background-size: contain;
  border-radius: var(--border-radius-100);

  &::after {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: v-bind(progressPercents);

    background-color: #3ca251; /* stylelint-disable-line */
    border-radius: var(--border-radius-100);
  }
}
</style>
