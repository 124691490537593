<template>
  <BaseBonusPanel
    type="freespin"
    :progress="progress"
    :paused="isRollingStatusNewOrPaused"
    class="freespin-panel"
    :class="status"
    @click="handlePanelClick"
  >
    <template #title>
      <i18n-t
        v-if="isBettingProgress"
        keypath="bonuses.freespinForGame"
        class="title-text"
      >
        <template #game>
          <b class="game-name">{{ gameName }}</b>
        </template>
      </i18n-t>
      <span v-else class="title-text" data-t="title-text">
        {{ title }} {{ levelTitle }}
      </span>
    </template>
    <template #wager>
      <span
        v-if="status === 'rolling' && isRollingStatusInProgress"
        class="wager"
      >
        {{ t('bonuses.wager', { multiplier: wager }) }}
      </span>
    </template>
    <template #subtitle>
      <p v-if="status === 'new'" class="subtitle" data-t="subtitle">
        {{ t('bonuses.shouldChooseGame') }}
      </p>
      <template v-if="status === 'inProgress'">
        <div
          v-if="freespin.freespinNumberLeft"
          class="rolling-data"
          data-t="rolling-data"
        >
          <p v-if="isRollingStatusInProgress" class="subtitle">
            {{
              t('bonuses.freespinsLeft', {
                count: freespin.freespinNumberLeft,
              })
            }}
          </p>
          <span
            class="bonus-amount default"
            :class="{ paused: isRollingStatusNewOrPaused }"
            data-t="bonus-amount"
          >
            <img :src="giftImage" alt="gift" width="20" />
            <span>
              {{ currentRewardAmount }}
            </span>
          </span>
        </div>

        <p v-else class="subtitle">
          {{ t('bonuses.resultHandlingTitle') }}
        </p>
      </template>
    </template>
    <template #minmax>
      <div v-if="status === 'rolling'" class="rolling-min-max">
        <span v-if="isRollingStatusInProgress" data-t="rolling-min-max">
          {{ minMaxRollingText }}
        </span>

        <span
          class="bonus-amount"
          :class="{ paused: isRollingStatusNewOrPaused }"
          data-t="bonus-amount"
        >
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ currentRewardAmount }}
          </span>
        </span>
      </div>
    </template>
    <template #actions>
      <StButton
        v-if="isRollingStatusNewOrPaused"
        size="m"
        type="secondary"
        :label="t('bonuses.toRoll')"
        @click="onSubmitClick"
      />
      <StButton
        v-else-if="status === 'new'"
        :label="t('bonuses.choose')"
        size="m"
        @click="onSubmitClick"
      />
      <StSpinner
        v-if="isCalculating"
        size="24"
        stroke-width="4"
        data-t="spiner"
      />
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import type { Freespin } from '../../../types'
import { useFreespin } from '../../../composables/useFreespin'
import { useGetTitleByLevel } from '../../../composables/useGetTitleByLevel'
import giftImage from '../assets/gift.png'

interface Props {
  freespin: Freespin
}

const props = defineProps<Props>()

const { t } = useI18n()
const { freespin } = toRefs(props)

const {
  title,
  status,
  onSubmitClick,
  wager,
  bettingProgress,
  rollingProgress,
  currentRewardAmount,
  currentRollingAmount,
  fullRollingAmount,
  gameName,
  isRollingStatusNewOrPaused,
  isRollingStatusInProgress,
} = useFreespin(freespin)

const { getTitle } = useGetTitleByLevel()

const levelTitle = computed(() =>
  getTitle('', freespin.value.bonusForDepositProgram?.level),
)

const isCalculating = computed(
  () => status.value === 'inProgress' && !props.freespin.freespinNumberLeft,
)
const isBettingProgress = computed(
  () => status.value === 'inProgress' && props.freespin.freespinNumberLeft,
)

const progress = computed(() => {
  switch (status.value) {
    case 'rolling':
      return rollingProgress.value
    case 'inProgress':
      return bettingProgress.value
    default:
      return ''
  }
})

const minMaxRollingText = computed(
  () => `${currentRollingAmount.value} / ${fullRollingAmount.value}`,
)

function handlePanelClick() {
  if (status.value === 'new' || isCalculating.value) return

  onSubmitClick()
}
</script>

<style scoped>
.wager {
  min-width: 80px;
  font: var(--mobile-caption-1-regular);
  text-align: right;
}

.subtitle {
  margin: 0;
  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  span {
    color: var(--text-primary);
  }
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.rolling-data {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-success);

  &.default {
    color: var(--text-primary);
  }

  &.paused {
    margin-left: 0;
  }
}

.freespin-panel {
  &:not(.new) {
    cursor: pointer;
  }
}

.game-name {
  font: var(--mobile-text-semibold);
  color: var(--text-link);
}

.title-text {
  overflow: hidden;
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
}
</style>
