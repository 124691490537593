<template>
  <BaseBonusPanel
    type="deposit"
    :progress="rollingProgress"
    :paused="isRollingStatusNewOrPaused"
  >
    <template #title> {{ titleByLevel }} </template>
    <template #wager>
      <span v-if="isRolling && isRollingStatusInProgress" class="wager">
        {{ t('bonuses.wager', { multiplier: rollingMultiplier }) }}
      </span>
    </template>
    <template #minmax>
      <div v-if="isRolling" class="rolling-min-max">
        <template v-if="isRollingStatusInProgress">
          <span class="rolling-min-max-value" :title="minMaxRollingText">
            {{ minMaxRollingText }}
          </span>
          <StIcon v-if="currencyIcon" :name="currencyIcon" :size="14" />
        </template>
        <span
          class="bonus-amount"
          :class="{ paused: isRollingStatusNewOrPaused }"
        >
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ bonusAmountForClaim }}
          </span>
          <StIcon v-if="currencyIcon" :name="currencyIcon" :size="14" />
        </span>
      </div>
    </template>
    <template #subtitle>
      <div v-if="isNewBonus" class="bonus-text new sub-bonuses">
        <div
          v-for="(subBonus, index) in filteredMultiBonuses"
          :key="index"
          class="sub-bonus"
        >
          <span v-if="isStepBonus">{{ t('bonuses.toAmount') }}</span>
          <span>
            {{ subBonus?.amount }}
          </span>
          <StIcon
            v-if="subBonus?.icon"
            :name="subBonus.icon"
            :size="16"
            class="icon"
          />
          <span v-if="subBonus?.text">
            {{ subBonus?.text }}
          </span>
          <span v-if="index + 1 < filteredMultiBonuses.length">,</span>
        </div>
      </div>
      <p v-if="isCanClaimed" class="bonus-text claim">
        {{ t('bonuses.depositPanelClaimText') }}
        <span>
          {{ bonusAmountForClaim }}
        </span>
        <StIcon v-if="currencyIcon" :name="currencyIcon" :size="14" />
      </p>
    </template>
    <template #actions>
      <StButton
        v-if="isRollingStatusNewOrPaused"
        size="m"
        type="secondary"
        :label="t('bonuses.toRoll')"
        @click="setCurrentRolling()"
      />
      <StButton
        v-else-if="isNewBonus"
        :label="t('bonuses.depositPanelMakeDeposit')"
        size="m"
        replace
        :to="{ query: { modal: 'payments', tab: 'deposit' } }"
      />
      <div v-else-if="isCanClaimed" class="claim-box">
        <StButton
          :label="t('bonuses.depositPanelClaim')"
          size="m"
          :loading="claimStatus === 'pending'"
          :disabled="claimStatus === 'error'"
          @click="handleBonusClaim"
        >
          {{ t('bonuses.depositPanelClaim') }}
          <ClientOnly>
            <StLottie
              v-if="claimStatus === 'success'"
              class="animation"
              :animation-data="claimAnimation"
            />
          </ClientOnly>
        </StButton>
      </div>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useDepositBonus } from '../../../composables/useDepositBonus'
import type { DepositBonus } from '../../../types'
import giftImage from '../assets/gift.png'
import claimAnimation from '../../../assets/claim-animation.json'

const props = defineProps<{
  bonus: DepositBonus
}>()

const { t } = useI18n()

const { bonus } = toRefs(props)

const {
  bonusesCurrencies,
  filteredMultiBonuses,
  isNewBonus,
  isRolling,
  isCanClaimed,
  bonusAmountForClaim,
  currentRollingAmount,
  fullRollingAmount,
  rollingProgress,
  rollingMultiplier,
  claimStatus,
  titleByLevel,
  isStepBonus,
  handleBonusClaim,
  isRollingStatusInProgress,
  isRollingStatusNewOrPaused,
  setCurrentRolling,
} = useDepositBonus(bonus)

const minMaxRollingText = computed(
  () => `${currentRollingAmount.value} / ${fullRollingAmount.value}`,
)

const currencyIcon = computed(() => bonusesCurrencies.value.default.icon)
</script>

<style scoped>
.bonus-text {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  &.new {
    span {
      margin-left: var(--spacing-025);
      background: linear-gradient(
        90deg,
        #ffe86d 42.95%,
        #f8db90 65.11%,
        #feb581 91.78%,
        #f09f7c 100%
      );
      background-clip: text;

      -webkit-text-fill-color: transparent;
    }
  }

  &.claim {
    span {
      margin-left: var(--spacing-025);
      color: var(--text-success);
    }
  }

  &.sub-bonuses {
    overflow: hidden;
    flex-wrap: wrap;
    max-width: 200px;
  }
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.rolling-min-max-value {
  overflow: hidden;
  max-width: 145px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wager {
  font: var(--mobile-caption-1-regular);
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-success);

  &.paused {
    margin-left: 0;
  }
}

.animation {
  position: absolute;
  overflow: visible;
}

.sub-bonus {
  overflow: hidden;
  display: flex;
  align-items: center;

  .icon {
    min-width: 14px;
    margin: 0 var(--spacing-025);
  }
}
</style>
