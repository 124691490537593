<template>
  <BaseBonusPanel
    type="freebet"
    :progress="progress"
    :paused="isRollingStatusNewOrPaused"
    :data-t="`freebet-panel-${status}`"
  >
    <template #title>
      <span v-if="status === 'rolling'">
        {{ t('bonuses.freebetWin') }}
      </span>
      <span v-else class="title">
        {{ t('bonuses.freeBetPanelTitle', { amount: freebet.amount }) }}
        <StIcon :name="freebet.currencyIcon" :size="14" class="icon" />
        {{ title }}
      </span>
    </template>
    <template #wager>
      <span
        v-if="status === 'rolling' && isRollingStatusInProgress"
        class="wager"
        data-t="wager"
      >
        {{ t('bonuses.wager', { multiplier: wager }) }}
      </span>
    </template>
    <template #subtitle>
      <p v-if="subtitle" class="subtitle" data-t="subtitle">
        {{ subtitle }}
      </p>
    </template>
    <template #minmax>
      <div
        v-if="status === 'rolling'"
        class="rolling-min-max"
        data-t="rolling-min-max"
      >
        <span v-if="isRollingStatusInProgress" class="rolling-text">
          <span class="rolling-text-amounts">
            {{ minMaxRollingTextWithoutCurrency }}
          </span>
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </span>

        <span
          class="bonus-amount"
          :class="{ paused: isRollingStatusNewOrPaused }"
          data-t="bonus-amount"
        >
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ currentRewardAmount }}
          </span>
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </span>
      </div>
    </template>
    <template #actions>
      <StButton
        v-if="isRollingStatusNewOrPaused"
        size="m"
        type="secondary"
        :label="t('bonuses.toRoll')"
        @click="setCurrentRolling"
      />
      <StButton
        v-else-if="buttonSettings"
        :label="buttonSettings.title"
        class="button-settings"
        size="m"
        type="ghost"
        data-t="action-button"
        :to="buttonSettings.to"
      />
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import type { Freebet } from '../../../types'
import giftImage from '../assets/gift.png'
import { useFreebet } from '../../../composables/useFreebet'
import { useGetTitleByLevel } from '../../../composables/useGetTitleByLevel'

interface Props {
  freebet: Freebet
}

const props = defineProps<Props>()

const { t } = useI18n()
const { freebet } = toRefs(props)

const {
  currentRewardAmount,
  minMaxRollingTextWithoutCurrency,
  wager,
  status,
  progress,
  isRollingStatusNewOrPaused,
  setCurrentRolling,
  isRollingStatusInProgress,
} = useFreebet(freebet)

const { getTitle } = useGetTitleByLevel()

const title = computed(() =>
  getTitle('', freebet.value.bonusForDepositProgram?.level),
)

const subtitle = computed(() => {
  switch (status.value) {
    case 'new':
      return t('bonuses.freebetMakeBet')
    case 'process':
      return t('bonuses.freebetBetProcessed')
    default:
      return ''
  }
})

const buttonSettings = computed(() => {
  switch (status.value) {
    case 'new':
      return {
        title: t('bonuses.openSport'),
        to: '/sport',
      }
    case 'process':
      return {
        title: t('bonuses.openBets'),
        to: '/personal/bets',
      }
    default:
      return null
  }
})
</script>

<style scoped>
.wager {
  font: var(--mobile-caption-1-regular);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .icon {
    margin: 0 var(--spacing-025);
  }
}

.subtitle {
  margin: 0;
  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  span {
    color: var(--text-primary);
  }
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-success);

  &.paused {
    margin-left: 0;
  }
}

.rolling-text {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.rolling-text-amounts {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-settings {
  white-space: nowrap;
}
</style>
